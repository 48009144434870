import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "building-an-example-journey-map"
    }}>{`Building an example Journey Map`}</h1>
    <br />
    <p>{`In this example we want to guide your through the process of building a journey map. To keep things simple,
this journey map describes a user interacting with a todo-list application, a use case everyone knows probably too well.`}</p>
    <br />
When opening Journey Mapping app for the first time, you will see this welcome screen.
    <Image width={'80%'} src='/public/tutorial/JME1.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`We will now add a new journey map, choose whatever name you want.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME2.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`After clicking on the newly created journey map, we will find an empty canvas for us to fill.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME3.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`First, we will add a persona for our journey. For now, this is mainly cosmetic, but there are many features
coming with regard to personas.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME4.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`After creating the persona, we can click select to associate it to the current journey.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME5.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`Next, lets map out our user journey. We can add an unlimited amount of steps, but it is advisable to keep to a maximum of
10 to keep the journey map manageable.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME6.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`After adding all the necessary steps, we can start creating Jira issues right from the canvas.`}</p>
    <p>{`We can use any available issue type in the project.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME7.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <Image width={'80%'} src='/public/tutorial/JME8.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`We can later view the journey map right from the linked ticket.`}</p>
    <Image width={'80%'} src='/public/tutorial/JME9.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      